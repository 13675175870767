<template>
  <el-form :inline="true" :model="form" ref="from">
    <el-form-item>
      <el-input
        style="width:350px"
        v-model="form.keyword"
        placeholder="编号 | 名称 | 签约人 | 项目 | 联系人 | 电话 | 负责人"
        @keypress.enter.native="getList"
      ></el-input>
    </el-form-item>
    <el-form-item><slot></slot></el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    inline: Boolean,
    form: Object
  },
  methods: {
    getList() {
      // alert('form')
      this.$emit('getList')
    }
  }
}
</script>
